import CableReady from 'cable_ready'
import consumer from "./consumer"

consumer.subscriptions.create({ channel: "UserChannel" }, {
  connected() {
    console.log("UserChannel connected")
  },

  disconnected() {
    console.log("UserChannel disconnected")
  },

  received(data) {
    console.log(data);

    if (data.cableReady) {
      CableReady.perform(data.operations)
    }
  }
});