export function removeQueryParameter(url, param) {
  const _url = new URL(url);
  const searchParams = new URLSearchParams(_url.search);

  if (searchParams.has(param)) {
    searchParams.delete(param);
    _url.search = searchParams;
  }

  return _url.href;
}

export function updateCurrentUrl(url) {
  history.replaceState({}, document.title, url);
}