import CableReady from 'cable_ready'
import consumer from "./consumer"

consumer.subscriptions.create("AlertsChannel", {
  connected() {
    console.log("AlertsChannel connected")
  },

  disconnected() {
    console.log("AlertsChannel disconnected")
  },

  received(data) {
    if (data.cableReady) {
      CableReady.perform(data.operations)
      document.querySelectorAll('sl-alert.popup-toast').forEach(function (a) {
        a.toast()
      })
    }
  }
});