import 'application.scss'
import * as url_utils from '../utils/url_utils';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('parsleyjs');

ParsleyConfig.autoBind = false;
var Cookies = require('js-cookie');
const datepicker = require('js-datepicker');
window.toastr = require('toastr');

toastr.options = {
  positionClass: 'toast-top-center',
  timeOut: '10000',
  closeButton: true,
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// window.Amplitude = require('amplitudejs');

import {
  Cloudinary
} from 'cloudinary-core';

window.cloudinary = new Cloudinary({
  cloud_name: "dkonzvabm",
  secure: true
});

import copy from 'copy-to-clipboard';
import tippy from 'tippy.js';

// StimulusReflex
import "controllers"

// components
// function importAll(r) {
//   r.keys().forEach(r)
// }

// importAll(require.context("../../components", true, /\.js$/))

// Chosen plan highlight
function highlightChosenPlan() {
  // Add active plan class on plan change
  $('.plan').click(function () {
    $('.plan').each(function () {
      $(this).removeClass('plan--active');
    });
    if ($(this).children('input[type=radio]:checked')) {
      $(this).addClass('plan--active');
    } else {
      $(this).removeClass('plan--active');
    }
  });
}

function bindParsleyForms() {
  $('[data-parsley-validate]').each(function () {
    new Parsley.Factory(this)
  });
}

// Cookies message
function showCookieMessage() {
  var hideCookies = Cookies.get('hideCookies');
  if (hideCookies !== 'true') {
    $('.cookiesMessage').removeClass('display-none');
    Cookies.set('hideCookies', 'true', {
      expires: 3650
    });
  }
}

function hideCookieMessage() {
  $('.cookiesOk').click(function () {
    $('.cookiesMessage').hide();
    Cookies.set('hideCookies', 'true', {
      expires: 3650
    });
    return false;
  });
}


function accordionToggle() {
  $('.accordion__title').click(function () {
    if ($(this).hasClass('accordion__title--expanded')) {
      $(this).next().removeClass('accordion__content--expanded');
      $(this).removeClass('accordion__title--expanded');
    } else {
      $('.accordion__content').removeClass('accordion__content--expanded');
      $('.accordion__title').removeClass('accordion__title--expanded');
      $(this).addClass('accordion__title--expanded');
      $(this).next().addClass('accordion__content--expanded');
    }
    return false;
  });
}

function accordionOpenAnchor() {
  var hash = $(location).attr('hash');
  hash = hash.replace('#','');
  $('.accordion__title').each(function () {
    var thisId = $(this).attr('id');
    if (thisId === hash) {
      $(this).addClass('accordion__title--expanded');
      $(this).next().addClass('accordion__content--expanded');
      return false;
    }
  });
}


function CopyBinding() {
  $('body').on('click.copyToClipboard', '[data-copy-to-clipboard]', function (
    e
  ) {
    var code = e.currentTarget.dataset.copyToClipboard;
    copy(code);
    var tooltip = tippy(e.currentTarget, {
      content: 'Copied ' + code,
      onHide(instance) {
        instance.setContent('');
      },
      placement: 'bottom'
    });
    tooltip.show();
  });
}


function tooltipBinding() {
  tippy('.tooltip', {
    placement: 'bottom'
  });
}

function htmlTooltipBinding() {
  tippy('.tooltipHTML', {
    content(reference) {
      const id = reference.getAttribute('data-template');
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    placement: 'left',
    allowHTML: true,
    interactive: true
  });
}

function toggleNavicon() {
  $('.navicon').click(function () {
    $(this).children('.mdi').toggleClass('mdi-menu');
    $(this).children('.mdi').toggleClass('mdi-close');
    $('.header-primary__nav').toggle();
    return false;
  });
}

// Set cookie for recalling 'try again' login link
function setTryAgainLink() {
  $('form#new_access_key input:submit, form#regForm input:submit').click(
    function () {
      var currentHref = window.location.href;
      Cookies.set('tryAgainLink', currentHref, {
        expires: 1 / 24
      });
    }
  );
}

function removeTryAgainLink() {
  $('form#loginForm input:submit').click(function () {
    Cookies.remove('tryAgainLink');
  });
}

function updateTryAgainLink() {
  var tryAgainLink = Cookies.get('tryAgainLink');
  if (tryAgainLink) {
    $('a.tryAgainLink').attr('href', tryAgainLink);
  }
}

function slugify(string) {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

function autofillArtistURL() {
  var initDisplayName = $('#artist_display_name').val();
  // Check display name hasn't been set - just in case
  if (!initDisplayName) {
    $('#artist_display_name').bind('change paste keyup', function () {
      var displayName = $('#artist_display_name').val();
      var slug = slugify(displayName);
      $('#artist_slug').val(slug);
    });
  }
}

function hideEmptyColumns() {
  var $table = $('table.hideEmptyColumns'),
    $thead = $table.find('thead'),
    $tbody = $table.find('tbody');

  var isEmpty = {};
  $tbody.find('td').each(function () {
    var $this = $(this);
    if ($this.text() == '' && isEmpty[$this.index()] != false) {
      isEmpty[$this.index()] = true;
    } else {
      isEmpty[$this.index()] = false;
    }
  });

  for (var x in isEmpty) {
    if (isEmpty[x]) {
      $thead.find('th').eq(x).hide();
      $tbody.find('tr').each(function () {
        $(this).find('td').eq(x).hide();
      });
    }
  }
}

// Google Analytics events
function gaEventTracking() {

  $('a.gaTrackDownload').click(function () {
    var trackName = $(this).attr('data-track');
    var trackArtist = $(this).attr('data-artist');
    var trackGenre = $(this).attr('data-genre');
    var currentPage = window.location.href;
    var val = trackName + ', by: ' + trackArtist + ', genre: ' + trackGenre + ', from: ' + currentPage;
    ga('send', 'event', 'Track', 'Download', val);
  });

  $('a.gaCopyBeatportLink').click(function () {
    var trackName = $(this).attr('data-track');
    var trackArtist = $(this).attr('data-artist');
    var trackGenre = $(this).attr('data-genre');
    var currentPage = window.location.href;
    var val = trackName + ', by: ' + trackArtist + ', genre: ' + trackGenre + ', from: ' + currentPage;
    ga('send', 'event', 'Track', 'Copy Beatport link', val);
  });

  $('a.openAudioPlayer').click(function () {
    var trackName = $(this).attr('data-track');
    var trackArtist = $(this).attr('data-artist');
    var trackGenre = $(this).attr('data-genre');
    var currentPage = window.location.href;
    var val = trackName + ', by: ' + trackArtist + ', genre: ' + trackGenre + ', from: ' + currentPage;
    ga('send', 'event', 'Track', 'Preview', val);
  });

  $('a.gaSearchButton').click(function () {
    var currentPage = window.location.href;
    var val = 'from: ' + currentPage;
    ga('send', 'event', 'User action', 'Search', val);
  });

  $('.gaDropboxRefresh').click(function () {
    var currentPage = window.location.href;
    var val = 'from: ' + currentPage;
    ga('send', 'event', 'User action', 'Dropbox Refresh', val);
  });

  $('a.gaCreateReleaseButton').click(function () {
    ga('send', 'event', 'User action', 'Create release button');
  });

  $('a[target]').click(function () {
    var href = $(this).attr('href');
    var currentPage = window.location.href;
    var val = href + ', from: ' + currentPage;
    ga('send', 'event', 'User action', 'External link click', val);
  });
}

// Toggle search form
function toggleSearchForm() {
  $('#toggleSearch').click(function () {
    $('#searchForm').toggle();
    $('#searchInput:visible').focus();
    if($('#searchInput:visible')) {
      $('#searchInput').val('');
      filterStuff();
    }
    $(this).toggleClass('active');
    return false;
  });
  $('#clearSearch').click(function () {
    $('#searchForm').hide();
    $('#toggleSearch').removeClass('active');
    $('#searchInput').val('');
    filterStuff();
    return false;
  });
  $('#searchInput').on('keyup', function () {
    filterStuff()
  });
}

function filterStuff() {
  var value = $('#searchInput').val().toLowerCase();
  $('.table tbody tr, .card, .card-grid, .choice').filter(function () {
    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    if ($('.table tbody tr').length && $('.table tbody tr:visible').length === 0) {
      $('#noResults').show();
      $('.table thead').hide();
    } else if ($('.card').length && $('.card:visible').length === 0) {
      $('#noResults').show();
    } else if ($('.card-grid').length && $('.card-grid:visible').length === 0) {
      $('#noResults').show();
    } else if ($('.choice').length && $('.choice:visible').length === 0) {
      $('#noResults').show();
    } else {
      $('#noResults').hide();
      $('.table thead').show();
    }
  });
}

// Token delete confirmation
function openModal() {
  $('.openModal').on('click', function () {
    var token = $(this).attr('data-modal-content');
    var modalId = "#modal-content-" + token;
    // Remove ability to focus on all focusable elements on page
    removeFocus();
    // Reinstate ability to focus on focusable elements in modal
    addFocus(modalId + ' .noFocus');
    $(modalId).show().trigger('pg:init');
    $(modalId).attr('aria-hidden', 'false');
    return false;
  });
}

function removeFocus() {
  $('a, button, :input, [tabindex]').each(function () {
    var tabIdx = $(this).attr('tabindex');
    if (tabIdx !== '-1') {
      $(this).attr('tabindex', '-1');
      $(this).addClass('noFocus');
    }
  });
}

function addFocus(selector) {
  $(selector).each(function () {
    $(this).attr('tabindex', '');
    $(this).removeClass('noFocus');
  });
}

function closeModal() {
  $('.modalClose').click(function () {
    $(this).parents('.modal').remove();
    (this).parents('.modal').attr('aria-hidden', 'true');
    addFocus('.noFocus');
    return false;
  });
}

function escModal() {
  $(document).keyup(function (e) {
    // If esc key pressed, close modal
    if (e.keyCode == 27) {
      $('.modal:visible').hide();
      closeAudioPlayers();
    }
  });
}


function applyArtistProfileUploadWidget() {
  var button = document.getElementById("artist_profile_pic_upload_opener");
  if (button) {
    cloudinary.applyUploadWidget(button, {
      clientAllowedFormats: ['png', 'jpeg'],
      maxImageFileSize: 10500000,
      minImageWidth: 500,
      minImageHeight: 500,
      multiple: false,
      cropping: true,
      showSkipCropButton: true,
      croppingAspectRatio: 1,
      cloudName: window.cloudinaryCloudName,
      folder: 'artists/' + artistId + '/profile', // set in the form template
      resourceType: 'image',
      uploadPreset: window.cloudinaryUnsignedUploadPreset,
      styles: {
        palette: {
          window: "#FFFFFF",
          windowBorder: "#90A0B3",
          tabIcon: "#0078FF",
          menuIcons: "#5A616A",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#00DCFF",
          action: "#FF620C",
          inactiveTabIcon: "#0E2F5A",
          error: "#F44235",
          inProgress: "#0078FF",
          complete: "#20B832",
          sourceBg: "#E4EBF1"
        },
        fonts: {
          default: {
            active: true
          }
        }
      }
    }, (error, result) => {
      if (!error && result && result.event === "success") {
        console.log('Done! Here is the image info: ', result.info);
        $('#artist_image_path').val(result.info.path)
        //$('form.formtastic.artist').submit()
      }
    })
  }
}

function applyReleaseUploadWidget() {
  var button = document.getElementById("release_image_upload_opener");
  if (button) {
    cloudinary.applyUploadWidget(button, {
      clientAllowedFormats: ['png', 'jpeg'],
      maxImageFileSize: 10500000,
      minImageWidth: 500,
      minImageHeight: 500,
      multiple: false,
      cropping: true,
      showSkipCropButton: true,
      croppingAspectRatio: 1,
      cloudName: window.cloudinaryCloudName,
      folder: 'artists/' + artistId + '/releases', // set in the form template
      resourceType: 'image',
      uploadPreset: window.cloudinaryUnsignedUploadPreset,
      styles: {
        palette: {
          window: "#FFFFFF",
          windowBorder: "#90A0B3",
          tabIcon: "#0070ac",
          menuIcons: "#5A616A",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#00DCFF",
          action: "#FF620C",
          inactiveTabIcon: "#0E2F5A",
          error: "#F44235",
          inProgress: "#0070ac",
          complete: "#20B832",
          sourceBg: "#E4EBF1",
          borderRadius: "0"
        },
        fonts: {
          default: {
            active: true
          }
        }
      }
    }, (error, result) => {
      if (!error && result && result.event === "success") {
        console.log('Done! Here is the image info: ', result.info);
        $('#release_image_path').val(result.info.path)
        //$('form.formtastic.artist').submit()
      }
    })
  }
}

function applyTrackUploadWidget() {
  var button = document.getElementById("track_image_upload_opener");
  if (button) {
    cloudinary.applyUploadWidget(button, {
      clientAllowedFormats: ['png', 'jpeg'],
      maxImageFileSize: 10500000,
      minImageWidth: 500,
      minImageHeight: 500,
      multiple: false,
      cropping: true,
      showSkipCropButton: true,
      croppingAspectRatio: 1,
      cloudName: window.cloudinaryCloudName,
      folder: 'artists/' + artistId + '/tracks', // set in the form template
      resourceType: 'image',
      uploadPreset: window.cloudinaryUnsignedUploadPreset,
      styles: {
        palette: {
          window: "#FFFFFF",
          windowBorder: "#90A0B3",
          tabIcon: "#0070ac",
          menuIcons: "#5A616A",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#00DCFF",
          action: "#FF620C",
          inactiveTabIcon: "#0E2F5A",
          error: "#F44235",
          inProgress: "#0070ac",
          complete: "#20B832",
          sourceBg: "#E4EBF1",
          borderRadius: "0"
        },
        fonts: {
          default: {
            active: true
          }
        }
      }
    }, (error, result) => {
      if (!error && result && result.event === "success") {
        console.log('Done! Here is the image info: ', result.info);
        $('#track_image_path').val(result.info.path)
        //$('form.formtastic.artist').submit()
      }
    })
  }
}

function toggleNameField() {
  var hideEmail = $('#access_key_hide_email').is(":checked");
  // toogle name field and validation
  if (hideEmail) {
    $('#access_key_name_input').slideDown();
    $('#access_key_name').attr('required', 'required');
  } else {
    $('#access_key_name_input').slideUp();
    $('#access_key_name').removeAttr('required');
  }
  // reset parsleyjs validation
  if ($('#new_access_key').length) {
    new Parsley.Factory($('#new_access_key'));
  }
}

function invokeToggleNameField() {
  $('#access_key_hide_email').click(function () {
    toggleNameField();
  });
}

function applyTokenAccessControls() {
  $("#select_all_track_ids").on('click', function (event) {
    $("#token_track_ids_input .choice input").prop('checked', true)
    event.preventDefault();
  });

  $("#clear_all_track_ids").on('click', function (event) {
    $("#token_track_ids_input .choice input").prop('checked', false)
    event.preventDefault();
  });

  $("#select_all_categories").on('click', function (event) {
    $("#token_categories_input .choice input").prop('checked', true)
    event.preventDefault();
  });

  $("#clear_all_categories").on('click', function (event) {
    $("#token_categories_input .choice input").prop('checked', false)
    event.preventDefault();
  });
}

function tabNavigation(el) {
  var contentId = el.attr('aria-controls');
  $('.tabsContent:visible').attr('hidden', 'hidden');
  $('#' + contentId).removeAttr('hidden');
}

function tabNavigationAction() {
  $('.tabsItem').click(function () {
    tabNavigation($(this));
  });
}

function showActiveTabOnLoad() {
  $('.tabsItem').each(function () {
    var isChecked = $(this).children('input[type=radio]').is(':checked');
    if (isChecked) {
      tabNavigation($(this));
    }
  });
}

function datePicker() {
  if ($('.tokenDatePicker').length) {
    const picker = datepicker('.tokenDatePicker', {
      minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      formatter: (input, date) => {
        input.value = formatDate(date);
      }
    });
  } else if ($('.releaseDatePicker').length) {
    const picker = datepicker('.releaseDatePicker', {
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 30)),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
      formatter: (input, date) => {
        input.value = formatDate(date);
      }
    });
  }
}

function downloadReleaseButtonAction() {
  $('.download-release-button').click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    const $link = $(this);

    $link.off('click');
    $link.attr('disabled', 'disabled');
    $link.addClass('disabled');

    toastr.info("We're zipping the release - your download will start shortly!");

    const url = $link.attr('href');

    // download the release
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status == 200) {
        const blob = new Blob([this.response], { type: 'application/zip' });
        const objectURL = window.URL.createObjectURL(blob);
        const filename = xhr.getResponseHeader("X-FileName");
        const tempLink = document.createElement('a');
        tempLink.href = objectURL;
        tempLink.setAttribute('download', filename || 'Release.zip');
        tempLink.style.display = 'none';
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      } else {
        toastr.error("Something went wrong");
      }

      // renable the download release button
      $link.removeAttr('disabled');
      $link.removeClass('disabled');
      downloadReleaseButtonAction();
    };
    xhr.send();
  });
}

function formatDate(date) {
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, "0")
  const day = `${date.getDate()}`.padStart(2, "0")
  return year + '-' + month + '-' + day // => '2020-09-28'
}

// Table sort
function tableSort() {
  $('.table--sorted th').click(function(){
    var table = $(this).parents('table').eq(0)
    var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).index()))
    $('.table--sorted th').removeClass('sorted').removeClass('descending');
    $(this).addClass('sorted');
    this.asc = !this.asc
    if (!this.asc){
      rows = rows.reverse()
      $(this).addClass('descending');
    }
    for (var i = 0; i < rows.length; i++){table.append(rows[i])}
  })
}

function comparer(index) {
  return function(a, b) {
      var valA = getCellValue(a, index), valB = getCellValue(b, index);
      return isNumber(valA) && isNumber(valB) ? valA - valB : valA.localeCompare(valB);
  }
}
function getCellValue(row, index){ return $(row).children('td').eq(index).attr('data-sort') };

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function removeTokenIdOnPreviewSelect() {
  $("#preview_select").change(function() {
    url_utils.updateCurrentUrl(url_utils.removeQueryParameter(window.location.href, "token_id"));
  });
}

// Invoke all functions
function invoke() {
  showCookieMessage();
  hideCookieMessage();
  toggleNavicon();
  CopyBinding();
  tooltipBinding();
  htmlTooltipBinding();
  highlightChosenPlan();
  setTryAgainLink();
  removeTryAgainLink();
  updateTryAgainLink();
  autofillArtistURL();
  hideEmptyColumns();
  // gaEventTracking();
  accordionToggle();
  accordionOpenAnchor();
  toggleSearchForm();
  bindParsleyForms();
  openModal();
  closeModal();
  escModal();
  toggleNameField();
  invokeToggleNameField();;
  applyArtistProfileUploadWidget();
  applyReleaseUploadWidget();
  applyTrackUploadWidget();
  applyTokenAccessControls();
  tabNavigationAction();
  showActiveTabOnLoad();
  datePicker();
  downloadReleaseButtonAction();
  tableSort();
  removeTokenIdOnPreviewSelect();
}

// Detect page change for Turbolinks and invoke functions
$(document).on('turbolinks:load', function () {
  invoke();
});