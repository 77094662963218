import consumer from "./consumer"

consumer.subscriptions.create({ channel: "ReloadChannel" }, {
  connected() {
    console.log("ReloadChannel connected")
  },

  disconnected() {
    console.log("ReloadChannel disconnected")
  },

  received(data) {
    window.location.reload();
  }
});