import ApplicationController from './application_controller'
import { useThrottle } from 'stimulus-use'

export default class extends ApplicationController {

  static targets = ['audio']
  static throttles = ['time']

  connect() {
    super.connect()
    useThrottle(this, { wait: 10000 })

    this.lastTime = 0;

    if(this.hasAudioTarget) {
      this.audioTarget.oncanplay = () => {
        this.lastTime = Math.round(this.audioTarget.currentTime)
      }

      this.audioTarget.onseeked = () => {
        this.stimulate("Player#skip");
        this.lastTime = Math.round(this.audioTarget.currentTime)
        console.log("Player#skip", this.lastTime);
      }

      this.audioTarget.ontimeupdate = this.time.bind(this);
    }
  }

  time() {
    if(this.hasAudioTarget) {
      if(!this.audioTarget.paused) {
        var currentTime = Math.round(this.audioTarget.currentTime)
        var delta = currentTime - this.lastTime;
        this.stimulate("Player#time", delta);
        console.log('this.stimulate("Player#time", delta);');
        this.lastTime = currentTime
      }
    } else {
      console.log("no audio target");
    }

  }

  next(event) {
    const trackId = this.element.dataset.id;
    const nowPlayingButton = $(`.click_player_play[data-id='${trackId}']`);
    var nextPlayButton = nowPlayingButton.parents('.track').next().find('.click_player_play');
    if (nextPlayButton[0]) {
      this.stimulate("Player#play", nextPlayButton[0]);
    } else {
      var firstPlayButton = $('.click_player_play').first();
      this.stimulate("Player#play", firstPlayButton[0]);
    }
    event.preventDefault();
  }

  previous(event) {
    const trackId = this.element.dataset.id;
    const nowPlayingButton = $(`.click_player_play[data-id='${trackId}']`);
    const prevPlayButton = nowPlayingButton.parents('.track').prev().find('.click_player_play');
    if (prevPlayButton[0]) {
      this.stimulate("Player#play", prevPlayButton[0]);
    } else {
      var lastPlayButton = $('.click_player_play').last();
      this.stimulate("Player#play", lastPlayButton[0]);
    }
    event.preventDefault();
  }

  playSuccess(element, reflex, noop, reflexId) {
    $('audio').each(function () {
      this.play();
    });
  }

  close() {
    this.element.parentNode.removeChild(this.element);
  }

}